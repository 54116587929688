import React from 'react'

export const videoWrapperClassName = 'video-stream-wrapper'
export const watermarkId = 'visible-watermark'

const styles: React.CSSProperties = {
  position: 'absolute',
  top: '0',
  left: '0',
  padding: '4.5rem',
  fontWeight: 700,
  fontSize: '1.8rem',
  color: '#ffffff',
  letterSpacing: 'normal',
  lineHeight: 'normal',
  textShadow:
    '1px 1px 0 black, 1px -1px 0 black, -1px -1px 0 black, -1px 1px 0 black, 2px 2px 5px rgb(0 0 0 / 25%)',
  display: 'none',
  pointerEvents: 'none',
  opacity: 0.6,
}

// we're doing it this way to make it less convenient to manually delete the watermark
export function turnOnWatermark(arr, dateString, base64Watermark) {
  try {
    const wrapperEl = document.querySelector(`.${videoWrapperClassName}`)
    if (!wrapperEl) throw new Error('No video wrapper element')
    // find position to overlay video corner
    const videoEl = wrapperEl.querySelector('video')
    const vidWidth = videoEl?.videoWidth
    const vidHeight = videoEl?.videoHeight
    const vidRect = videoEl?.getBoundingClientRect()
    if (vidRect && vidWidth && vidHeight) {
      const heightRatio = vidRect.height / vidHeight
      const widthRatio = vidRect.width / vidWidth
      if (heightRatio < widthRatio) {
        const ratio = vidRect.height / vidHeight
        const actualWidth = ratio * vidWidth
        const leftPos = (vidRect.width - actualWidth) / 2
        styles.left = leftPos + 'px'
        styles.top = '0'
      } else {
        const ratio = vidRect.width / vidWidth
        const actualHeight = ratio * vidHeight
        const wrapperRect = wrapperEl.getBoundingClientRect() // the align-items center on the wrapper means we need to get this dimention when the heightRatio is greater than the widthRatio
        const topPos = (wrapperRect.height - actualHeight) / 2
        styles.top = topPos + 'px'
        styles.left = '0'
      }
    }
    styles.display = ''
    // create a new element
    const oldEl = wrapperEl.querySelector(`#${watermarkId}`)
    const newWatermarkEl = document.createElement('div')
    newWatermarkEl.id = watermarkId
    wrapperEl.append(newWatermarkEl)

    const info = arr.map((text) => `<div>${text}</div>`).join('')
    const timeDiv = `<div>${dateString}</div>`
    newWatermarkEl.innerHTML = info + timeDiv
    for (const [prop, val] of Object.entries(styles)) {
      newWatermarkEl.style[prop] = val
    }
    oldEl?.remove()

    if (base64Watermark) {
      addImageWatermark(wrapperEl, base64Watermark)
    } else {
      const oldImgEl = wrapperEl.querySelector(`#${watermarkId}-img`)
      oldImgEl?.remove();
    }
  } catch (err) {
    console.error('Tampering with the watermark')
  }
}
export function turnOffWatermark() {
  try {
    const imageEl: HTMLElement | null = document.querySelector(`#${watermarkId}-img`)
    if (imageEl) {
      imageEl.style.display = 'none'
    }

    const watermarkEl = document.querySelector(`#${watermarkId}`)
    styles.display = 'none'
    for (const [prop, val] of Object.entries(styles)) {
      // @ts-ignore
      watermarkEl.style[prop] = val
    }
  } catch { }
}

function createOverlay(wrapperEl, imageElement: HTMLImageElement) {
  const overlay = document.createElement('div');
  overlay.className = 'video-overlay';
  overlay.style.background = 'none';
  overlay.style.height = '100%';
  overlay.style.left = '0';
  overlay.style.margin = '0';
  overlay.style.padding = '0';
  overlay.style.pointerEvents = 'none';
  overlay.style.top = '0';
  overlay.style.width = '100%';
  overlay.style.position = 'absolute';
  overlay.id = `${watermarkId}-img`
  overlay.appendChild(imageElement);
  wrapperEl.appendChild(overlay);
}

function createImg(imageData): HTMLImageElement {
  const imageElement = new Image();
  imageElement.style.width = '100%';
  imageElement.style.height = '100%';
  imageElement.src = imageData;
  imageElement.style.pointerEvents = 'none';
  return imageElement;
}

function addImageWatermark(wrapperEl, base64Watermark) {
  const oldImgEl = wrapperEl.querySelector(`#${watermarkId}-img`)
  oldImgEl?.remove();
  const imageElement = createImg(base64Watermark);
  createOverlay(wrapperEl, imageElement);
}