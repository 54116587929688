import React, { useEffect, useState } from 'react'
import { OrganizationFeatureFlag, RoomFeatureFlag, RoomInvite, RoomInviteType } from '../../Models/apiEntities'
import { Box, Grid, Tooltip, Typography } from '@material-ui/core'
import { HelpOutline } from '@material-ui/icons'
import ShareRoomInput from './ShareRoomInput/ShareRoomInput'
import RoomInviteList from './RoomInviteList/RoomInviteList'
import RoomInviteForm from './RoomInviteForm/RoomInviteForm'
import { useRoomInviteForm } from './hooks/useRoomInviteForm'
import RoomSpecialInviteInput from './RoomSpecialInviteInput/RoomSpecialInviteInput'
import { WithCondition } from '../WithCondition/WithCondition'
import useApi from '../../API/useApi'
import { useAlerts } from '../../Providers/AlertsProvider'
import { AlertType } from '../Alert/Alert'
import Tabs from '../Tabs/Tabs'

interface RoomAccessProps {
  room: {
    id: string
    hash: string
    isPublic: boolean
  }
}

/**
 *  Room Access Management Component. Composed of ShareInput, InviteList and InviteForm components
 * @param room Object that holds the room`s information
 * @constructor
 */
const RoomAccess: React.FC<RoomAccessProps> = ({ room }) => {
  const { Room } = useApi()
  const alert = useAlerts()
  const [invites, setInvites] = useState<{ contributors: RoomInvite[], viewers: RoomInvite[] }>({ contributors: [], viewers: [] })
  const [isInProgress, setIsInProgress] = useState(false)
  const [tab, setTab] = useState('contributors')

  const performCreateRoomInvite = async (newInvites) => {
    setIsInProgress(true)
    try {
      const emails = newInvites.map((invite) => invite.email)
      await Room.createInvites(room.id, emails, tab === 'contributors' ? 'room_basic_access' : 'room_viewer')
      getRoomInvites()
    } catch {
      alert('Oops, there was an error adding the new room invitees', AlertType.error)
    }
    setIsInProgress(false)
  }

  const { handleSubmit, inputs } = useRoomInviteForm({ performCreateRoomInvite, invites: invites.contributors.concat(invites.viewers) })

  const getRoomInvites = async () => {
    try {
      const response = await Room.getRoomInvites(room.id)
      const allInvites = {
        contributors: [
          ...response.contributors.users.map((user) => ({
            id: user.email,
            name: user.email,
            type: RoomInviteType.USER,
          })),
          ...response.contributors.accountGroups.map((group) => ({
            id: group.id,
            name: group.name,
            type: RoomInviteType.GROUP,
          })),
        ],
        viewers: [
          ...response.viewers.users.map((user) => ({
            id: user.email,
            name: user.email,
            type: RoomInviteType.USER,
          })),
          ...response.viewers.accountGroups.map((group) => ({
            id: group.id,
            name: group.name,
            type: RoomInviteType.GROUP,
          })),
        ]
      }
      setInvites(allInvites)
    } catch {
      alert('Oops, there was an error loading the room invitees', AlertType.error)
    }
  }

  const onRemoveInvite = (type) => async (inviteId) => {
    try {
      await Room.deleteInvite(room.id, inviteId, type)
      getRoomInvites()
    } catch {
      alert('Oops, there was an error removing the room invitee', AlertType.error)
    }
  }

  const [hideSpecialInvite, setHideSpecialInvite] = useState<boolean>()
  const [isRoomViewOnlyEnabled, setIsRoomViewOnlyEnabled] = useState<boolean>()
  const isRoomMFAEnabled = async () => {
    try {
      const features = await Room.getFeatures(room.id)
      setHideSpecialInvite(features[OrganizationFeatureFlag.allowRoomMFA].value === 'true')
      setIsRoomViewOnlyEnabled(features[RoomFeatureFlag.allowRoomViewOnly].value === 'true')
    } catch (error) { }
  }

  useEffect(() => {
    isRoomMFAEnabled()
  }, [room])

  useEffect(() => {
    let active = true

    if (active && room) {
      getRoomInvites()
    }

    return () => {
      active = false
    }
  }, [room])

  const getTabComponent = (type) => {
    return <Box marginTop={2}>
      <Grid container direction="row" spacing={1}>
        <Grid item style={type === 'viewers' ? { marginBottom: '7px' } : {}}>
          <Typography variant="body2" color="textSecondary" gutterBottom>
            {type === 'contributors' ? 'Who has access' : 'Who has viewer access'}
          </Typography>
        </Grid>
        {
          type === 'contributors' && <Grid item>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              <Tooltip
                title="Participants won't be listed here if they removed the room from their dashboard."
                arrow
              >
                <HelpOutline fontSize="small" color="inherit"></HelpOutline>
              </Tooltip>
            </Typography>
          </Grid>
        }
      </Grid>
      <RoomInviteList 
        invites={invites[type]} 
        room={room} 
        onRemoveInvite={
          onRemoveInvite(type === 'contributors' ? 'room_basic_access' : 'room_viewer')
        } />
    </Box>
  }

  const tabs = [
    {
      label: 'Contributors',
      value: 'contributors',
      component: getTabComponent('contributors')
    },
    {
      label: 'Viewers',
      value: 'viewers',
      component: getTabComponent('viewers')
    }
  ]

  return (
    <>
      <Box marginTop={1}>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Room link to share
        </Typography>
        <ShareRoomInput roomHash={room.hash} />
      </Box>
      {hideSpecialInvite ? (
        <></>
      ) : (
        <Box marginTop={1}>
          <WithCondition condition={!room.isPublic}>
            <Typography variant="body2" color="textSecondary" gutterBottom>
              Single-use room access link (expires in 5 minutes)
            </Typography>
            <RoomSpecialInviteInput roomHash={room.hash} roomId={room.id} />
          </WithCondition>
        </Box>
      )}

      {isRoomViewOnlyEnabled ?
        <Box marginTop={3}>
          <Tabs padding={0} tabs={tabs} onTabChange={(tab) => setTab(tab)} />
        </Box>
        :
        tabs[0].component
      }
      <Box marginTop={3}>
        <Typography variant="body2" color="textSecondary" gutterBottom>
          Invite { isRoomViewOnlyEnabled ? (tab === 'contributors' ? 'contributors' : 'viewers') : 'participants' }
        </Typography>
        <RoomInviteForm inputs={inputs} onSubmit={handleSubmit} isInProgress={isInProgress} />
      </Box>
    </>
  )
}

export default React.memo(RoomAccess)
