import React from 'react'
import StreamPlayer from '../../StreamPlayer/StreamPlayer'
import MainStreamPlayerCanvases from '../../../../../../../Components/MainStreamPlayerCanvases/MainStreamPlayerCanvases'
import { LiveroomParticipant } from '../hooks/useLiveroomParticipants'
import './MainVideoStream.scss'
import { IDrawCanvas } from '../../../../../../../Common/drawtools/types'
import { StreamSelectionControl } from '../hooks/useLiveroom'
import NoContributors from '../NoContributors/NoContributors'

interface MainVideoStreamProps
  extends Pick<IDrawCanvas, 'setVideoNode' | 'setLocalCanvasNode' | 'setParticipantCanvasNode'> {
  stream: StreamSelectionControl
  participants: LiveroomParticipant[]
  canPublish: boolean
  isPlayback?: boolean
  isDrawerOpen?: boolean
  watermarkHidden: boolean
  setIsToolbarShowAlways: (value: boolean) => void
  setIsParticipantListOpen: (value: boolean) => void
  setIsChatOpenDesktop: (value: boolean) => void
}

const MainVideoStream: React.FC<MainVideoStreamProps> = ({
  stream,
  isDrawerOpen,
  participants,
  canPublish,
  isPlayback,
  watermarkHidden,
  setVideoNode,
  setLocalCanvasNode,
  setParticipantCanvasNode,
  setIsToolbarShowAlways,
  setIsParticipantListOpen,
  setIsChatOpenDesktop,

}) => {

  return (
    <MainStreamPlayerCanvases
      isDrawerOpen={isDrawerOpen}
      participants={participants}
      setLocalCanvasNode={setLocalCanvasNode}
      setParticipantCanvasNode={setParticipantCanvasNode}
    >
      {!isPlayback && !canPublish && !participants.find((p) => p.publishing) ?
        <NoContributors
          setIsToolbarShowAlways={setIsToolbarShowAlways}
          setIsParticipantListOpen={setIsParticipantListOpen}
          setIsChatOpenDesktop={setIsChatOpenDesktop}
        />
        :
        <StreamPlayer
          className="stream-player"
          isMuted={true}
          stream={stream.active}
          setVideoNode={setVideoNode}
          watermarkHidden={watermarkHidden}
          isMainStreamPlayer={true}
          streamType={stream.type}
          streamSelectedId={stream.selectedId}
        />
      }
    </MainStreamPlayerCanvases>
  )
}

export default MainVideoStream
