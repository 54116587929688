import React, { MutableRefObject, useEffect, useRef, useState } from 'react'
import { Stream as StreamType, StreamState } from '../../../../../../Common/media/stream'
import { CSDErrorCodes, useCSDContext } from '../../../../../../Providers/CSDProvider'
import { DamStream } from '../LiveRoom/hooks/DamStream'
import { Watermark } from '../../../../../../Components/Watermark/Watermark'
import { ParticipantType } from '../../../../../../Common/janus/clients/liveroom/LiveroomClient'
import StreamError from './StreamError'
import { turnOffWatermark } from '../../../../../../Components/Watermark/toggleWatermark'

/**
 * SinkId is still experimental, we need to add it ourselves
 */
declare global {
  interface HTMLMediaElement {
    setSinkId?(sinkId: string | undefined | null): Promise<undefined>
  }
}

interface StreamProps {
  className?: string
  stream: StreamType
  volume?: number
  isMuted: boolean
  setVideoNode?: (el: HTMLVideoElement | null) => void
  watermarkHidden?: boolean
  isReceivingVideo?: boolean
  isMainStreamPlayer?: boolean
  streamType?: ParticipantType
  streamSelectedId?: string
}

/**
 * React component that binds {@link MediaStream} to the {@link HTMLVideoElement}
 * @param className
 * @param stream
 * @param sinkId
 * @param isMuted
 * @constructor
 */
const StreamPlayer: React.FC<StreamProps> = ({
  className,
  stream,
  isMuted,
  setVideoNode,
  volume = 1,
  watermarkHidden = false,
  isReceivingVideo = true,
  isMainStreamPlayer = false,
  streamType = ParticipantType.Local,
  streamSelectedId = ''
}) => {
  const videoElementRef = useRef<HTMLVideoElement | null>(null)
  const audioElementRef = useRef<HTMLVideoElement | null>(null)
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null)
  const [damStream, setDamStream] = useState<DamStream | null>(null)
  const [speaker, setSpeaker] = useState<MediaDeviceInfo | null>(null)
  const [watermarkError, setWatermarkError] = useState<boolean>(false)
  const { sendEntryToCSD } = useCSDContext()

  useEffect(() => {
    if (stream.state === StreamState.available && audioElementRef.current) {
      audioElementRef.current.volume = volume
    }
  }, [stream, volume])

  useEffect(() => {
    if (videoElementRef.current && setVideoNode) {
      setVideoNode(videoElementRef.current)
    }
    setWatermarkError(false)
    if (stream.state === StreamState.available && !(stream as DamStream).src) {
      setMediaStream(stream.current)
      setSpeaker(stream.speaker)
      setDamStream(null)
    } else if (stream.state === StreamState.available && (stream as DamStream).src) {
      setDamStream(stream as DamStream)
      setMediaStream(null)
      setSpeaker(stream.speaker)
    } else if (stream.state === StreamState.error) {
      sendEntryToCSD(CSDErrorCodes.noAudio)
    } else {
      setMediaStream(null)
      setSpeaker(null)

      if (videoElementRef.current) {
        videoElementRef.current.dispatchEvent(new Event('ec-loadmetadata'))
      }
    }
  }, [stream, setVideoNode, sendEntryToCSD])

  useEffect(() => {
    const videoElem = videoElementRef.current
    const audioElem = audioElementRef.current
    if (!videoElem || !audioElem) {
      return
    }
    if (mediaStream) {
      videoElem.srcObject = isReceivingVideo ? mediaStream : null;
      audioElem.srcObject = mediaStream
    } else if (damStream) {
      videoElem.srcObject = null
      audioElem.srcObject = null
      damStream.addVideoReference(
        videoElementRef as MutableRefObject<HTMLVideoElement>,
        {
          useToTrackTimeUpdate: !isMainStreamPlayer /* The thumbnail video element determines the currentTime of the stream */,
        }
      )
    } else {
      videoElem.srcObject = null
      audioElem.srcObject = null
    }

    if (videoElementRef.current) {
      videoElementRef.current.dispatchEvent(new Event('ec-loadmetadata'))
    }
  }, [mediaStream, isReceivingVideo, damStream, videoElementRef, audioElementRef, isMainStreamPlayer])

  useEffect(() => {
    const audioElem = audioElementRef.current
    if (!audioElem || !audioElem.setSinkId) {
      return
    }

    audioElem.setSinkId(speaker?.deviceId ?? 'default')
  }, [speaker])

  useEffect(() => {
    if (watermarkError) {
      turnOffWatermark()
    }
  }, [watermarkError])

  return (
    <>
      { !watermarkError && <audio
        style={{ display: 'none' }}
        className={className}
        muted={isMuted}
        autoPlay={true}
        playsInline={true}
        ref={audioElementRef}
      /> }
      { !watermarkError && <video
        className={className}
        muted={true}
        autoPlay={true}
        playsInline={true}
        ref={videoElementRef}
      />}
      { watermarkError && <StreamError/> }
      { !watermarkError && <Watermark
        streamType={streamType}
        streamSelectedId={streamSelectedId}
        watermarkHidden={watermarkHidden}
        watermarkError={watermarkError}
        setWatermarkError={setWatermarkError}
      />}
    </>
  )
}

export default React.memo(StreamPlayer)
