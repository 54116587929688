import { useEffect, useState } from 'react'
import { ParticipantType } from '../../../Common/janus/clients/liveroom/LiveroomClient'
import { useRoom } from '../../../Providers/RoomProvider'
import { useSession } from '../../../Views/MainView/hooks/useSession'
import { turnOffWatermark, turnOnWatermark } from '../toggleWatermark'
import useApi from '../../../API/useApi'
import { Watermark } from '../../../Models/apiEntities'

interface UseWatermarkProps {
  streamType: ParticipantType
  streamSelectedId: string
  watermarkHidden: boolean
  watermarkError: boolean
  setWatermarkError: (w: boolean) => void
}

// These options are just Jake's best attempt at keeping the date short,
// but also making sure the month/day don't get confused, the AM/PM doesn't
// get confused and the timezone is always visible
const dateOptions = {
  hourCycle: 'h12',
  timeZoneName: 'short',
  year: 'numeric',
  month: 'short',
  day: 'numeric',
  hour: 'numeric',
  minute: 'numeric',
}

export const useWatermark = (props: UseWatermarkProps) => {
  const { streamType, streamSelectedId, watermarkHidden, watermarkError, setWatermarkError } = props
  const { userProfile } = useSession()
  const { Room } = useApi()
  const room = useRoom()
  const [streamWatermark, setStreamWatermark] = useState<Watermark | null>();

  const getWatermarking = async (_streamId) => {
    try {
      const response = await Room.getWatermarks(room.id);
      setStreamWatermark(response?.watermarks[0]);
    } catch (e) {
      console.error(e.message)
      setWatermarkError(true)
      return null
    }
  }

  useEffect(() => {
    if ((streamType === ParticipantType.Ebs ||
      streamType === ParticipantType.BrowserStream ||
      streamType === ParticipantType.Playback ||
      streamType === ParticipantType.DAMStream) &&
      (!streamWatermark || new Date().getTime() > new Date(streamWatermark.expiresAt).getTime()) &&
      !watermarkHidden
    ) {
      getWatermarking(streamSelectedId)
    }
  }, [streamSelectedId, watermarkHidden]);

  useEffect(() => {
    if (watermarkHidden) {
      setStreamWatermark(null)
    }
  }, [watermarkHidden])

  // just leave the function if watermarking is not turned on
  if (room && room.featureFlagsRest && room.featureFlagsRest?.ALLOW_WATERMARK && room.featureFlagsRest?.ALLOW_WATERMARK.value !== 'true') return

  // if watermarkError then leave
  if (watermarkError) return

  const date = new Date()
  const dateString = new Intl.DateTimeFormat(
    'en-US',
    dateOptions as Intl.DateTimeFormatOptions
  ).format(date)

  const email = userProfile?.email
  // once we have the email, we have the most important part of data. The rest is helping identify if there's not an email for whatever reason
  const displayName = userProfile?.displayName === email ? null : userProfile?.displayName
  const roomName = email ? null : room.displayName

  const info = [email, displayName, roomName].filter(Boolean)

  if (
    streamType === ParticipantType.Playback ||
    streamType === ParticipantType.Ebs ||
    streamType === ParticipantType.BrowserStream ||
    streamType === ParticipantType.DAMStream ||
    streamType === ParticipantType.RemoteApp
  ) {
    turnOnWatermark(info, dateString, streamWatermark ? `data:${streamWatermark.contentType};base64,${streamWatermark.content}` : null)
  } else {
    turnOffWatermark()
  }
}
