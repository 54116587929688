import React from 'react'
import { Typography } from '@material-ui/core'


const StreamError: React.FC = () => {
  return (
    <Typography variant={'body1'}>
      Sorry, we're having security issues with loading your stream. Please
      <a href={process.env.REACT_APP_SUPPORT_URL} target="_blank" rel="noopener noreferrer">
        <strong> contact Evercast Support </strong>
      </a>
      for more information.
    </Typography>
  )
}

export default StreamError
