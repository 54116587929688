import Janus from './janus'
import adapter from 'webrtc-adapter'
import { once } from 'ramda'

const JanusInit = once(() => {
  Janus.init({
    debug: 'all',
    dependencies: Janus.useDefaultDependencies({
      adapter
    }),
    callback: () => {
      console.log('Janus initialized.')
    }
  })
})

export default JanusInit
