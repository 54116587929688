import React, { useCallback } from 'react'

import { useParams } from 'react-router-dom'

import QueryRenderer from '../../../../GraphQL/QueryRenderer'
import AuthenticatedRoomViewQuery from '../../../../GraphQL/queries/AuthenticatedRoomViewQuery'
import Room from './Room/Room'
import { AlertsProvider } from '../../../../Providers/AlertsProvider'
import { RoomProvider } from '../../../../Providers/RoomProvider'
import NotFoundRedirect from '../../NotFoundView/NotFoundRedirect'

/**
 * Room entry point for a case when user is already logged in.
 * @constructor
 */
const RoomView: React.FC = () => {
  const { hash } = useParams<{ hash: string }>()

  return (
    <QueryRenderer
      query={AuthenticatedRoomViewQuery}
      variables={{ hash }}
      renderError={() => <NotFoundRedirect />}
      renderResult={useCallback((props, refreshGraphQLQuery) => {
        return (
          <AlertsProvider anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <RoomProvider room={props.room}>
              <Room refreshGraphQLQuery={refreshGraphQLQuery} {...props} />
            </RoomProvider>
          </AlertsProvider>
        )
      }, [])}
    />
  )
}

export default React.memo(RoomView)
