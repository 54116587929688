import React from 'react'

import { useWatermark } from './hooks/useWatermark'
import { ParticipantType } from '../../Common/janus/clients/liveroom/LiveroomClient'

interface WatermarkProps {
  streamType: ParticipantType
  streamSelectedId: string
  watermarkHidden: boolean
  watermarkError: boolean
  setWatermarkError: (w: boolean) => void
}

export const Watermark = (props: WatermarkProps) => {
  useWatermark({ ...props })

  return (
    <></>
  )
}
