import React from 'react'
import MainVideoStream from '../../../RoomView/Room/LiveRoom/MainVideoStream/MainVideoStream'
import { useDrawCanvasDisplayContext } from '../../../../../../Providers/DrawCanvasDisplayProvider'
import { LiveroomParticipant } from '../../../RoomView/Room/LiveRoom/hooks/useLiveroomParticipants'
import { StreamSelectionControl } from '../../../RoomView/Room/LiveRoom/hooks/useLiveroom'
import { noop } from 'rxjs'

interface PlaybackMainVideoStreamProps {
  stream: StreamSelectionControl
  participants: LiveroomParticipant[]
  watermarkHidden: boolean
}

const PlaybackMainVideoStream: React.FC<PlaybackMainVideoStreamProps> = ({
  stream,
  participants,
  watermarkHidden,
}) => {
  const {
    setVideoNode,
    setLocalCanvasNode,
    setParticipantCanvasNode,
  } = useDrawCanvasDisplayContext()

  return (
    <MainVideoStream
      participants={participants}
      canPublish={false}
      isPlayback={true}
      stream={stream}
      watermarkHidden={watermarkHidden}
      setVideoNode={setVideoNode}
      setLocalCanvasNode={setLocalCanvasNode}
      setParticipantCanvasNode={setParticipantCanvasNode}
      setIsChatOpenDesktop={noop}
      setIsParticipantListOpen={noop}
      setIsToolbarShowAlways={noop}/>
  )
}

export default PlaybackMainVideoStream
